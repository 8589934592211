import QrScanner from "qr-scanner";
import { useRef, useEffect, useCallback, useContext } from "react";
import { CameraPickUpContext } from "../pages/ShipmentsOfPickups";
export default function MobileViewPickUp() {
  const { res, setRes } = useContext(CameraPickUpContext);
  const updatedResult = useCallback(() => {
    console.log("Result changed and the new one is", res);
  }, [res]);
  const videoElem = useRef(null);
  const handleResCaptured = async (result) => {
    if (!res.some((r) => r === result.data)) {
      console.log("detected something ", result.data);
      setRes((old) => [...old, result.data.toUpperCase()]);
      updatedResult();
    }
  };

  useEffect(() => {
    if (videoElem.current) {
      try {
        const qrScanner = new QrScanner(videoElem.current, handleResCaptured, {
          maxScansPerSecond: 1,
          returnDetailedScanResult: true,
        });
        qrScanner.start();
      } catch (err) {
        console.log("error found");
      }
    }
  }, [videoElem]);
  return (
    <div className="scanner-container">
      <video ref={videoElem}></video>
    </div>
  );
}
