import React, { useContext } from "react";
import { RunnerContext } from "../Main";
import { ConfirmPickupShipments } from "../helpers";

export default function ConfirmationPickupDialog({
  shipments,
  shipmentsLength,
  setOpen,
}) {
  const { runnerData, setRunnerData } = useContext(RunnerContext);
  const submitConfirm = async () => {
    await ConfirmPickupShipments(runnerData, setRunnerData, shipments);
  };

  return (
    <div>
      <div className="confirmation-container">
        <div className="confirmation-dialog">
          <div className="confirmation-body">
            you are about to confirm that you are recieving {shipmentsLength}{" "}
            {shipmentsLength === 1 ? "shipment" : "shipments"}
          </div>
          <div className="confirmation-actions">
            <div className="confirmation-cancel" onClick={() => setOpen(false)}>
              cancel
            </div>
            <div className="confirmation-ok" onClick={submitConfirm}>
              confirm
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
