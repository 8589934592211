import { useEffect, createContext, useState } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { Shipments, Login, Pickups, Home, ShipmentsOfPickups } from "./pages";
import QRCode from "qrcode.react";
export const RunnerContext = createContext();
export default function Main() {
  const navigate = useNavigate();
  const [runnerData, setRunnerData] = useState(() => {
    const storedRunnerData = localStorage.getItem("runnerData");
    return storedRunnerData ? JSON.parse(storedRunnerData) : null;
  });

  // useEffect(() => {
  //   if (localStorage.getItem("runnerData") && !runnerData) {
  //     setRunnerData(JSON.parse(localStorage.getItem("runnerData")));
  //   }
  // }, [runnerData]);

  useEffect(() => {
    console.log("runnerData", runnerData);
  }, [runnerData]);
  useEffect(() => {
    if (!runnerData) {
      navigate("/login"); // Navigate to login page if runnerData is null
    }
  }, [runnerData, navigate]);

  return (
    <RunnerContext.Provider value={{ runnerData, setRunnerData }}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />
        <Route path="/scan-shipments" element={<Shipments />} />
        <Route path="/scan-pickups" element={<Pickups />} />
        <Route
          path="/scan-pickups/shipments-of-pickup/:pickupID"
          element={<ShipmentsOfPickups />}
        />
        <Route path="/qr-code" element={<QRCode />} />
      </Routes>
    </RunnerContext.Provider>
  );
}
