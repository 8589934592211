import React, { useState } from "react";
import axios from "axios";
import styles from "../assets/css/pickups.module.css";
import { Link } from "react-router-dom";
import Logout from "../components/Logout";

export default function Pickups() {
  const [accountNumber, setAccountNumber] = useState("");
  const [pickupsData, setPickupsData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const runnerData = JSON.parse(localStorage.getItem("runnerData"));
  const { accessToken, runnerID, companyID } = runnerData;

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await axios.get(
        `https://vsoftrunners.com-eg.net/api/RunnerUsers/V7/GetPickupsByAccountNo/${runnerID}/${accountNumber}`,
        {
          headers: {
            companyID: companyID,
            accessToken: accessToken,
          },
        }
      );
      setPickupsData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching pickups data:", error);
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className={styles.container}>
        <div className="display-flex">
          <div
            style={{
              fontSize: "20px",
              color: "#00347b",
              fontWeight: "bold",
            }}
          >
            Scan Pickup
          </div>
          <Logout />
        </div>
        <form onSubmit={handleSearch} className="header-form">
          <div className={styles.accountContainer}>
            <input
              className={styles.accountNumberInput}
              required
              placeholder="Account Number"
              type="text"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              style={{ marginTop: "20px" }}
            />
          </div>
          <button className="search-btn" type="submit">
            Search
          </button>
          {isLoading && <div className={styles.loader}></div>}
        </form>
        {pickupsData?.length > 0 ? (
          <div className={styles.tableContainer}>
            <table className={styles.contentTable}>
              <thead>
                <tr>
                  <th>PickUps</th>
                </tr>
              </thead>
              <tbody>
                {pickupsData?.map((pickup) => (
                  <tr key={pickup.pickupId}>
                    <Link
                      style={{ textDecoration: "none", color: "#00347b" }}
                      to={`/scan-pickups/shipments-of-pickup/${pickup.pickupId}`}
                    >
                      <td>{pickup.pickupId}</td>
                    </Link>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : pickupsData?.length === 0 ? (
          <div className={styles.noPickupsCard}>No PickUps</div>
        ) : pickupsData ? (
          <></>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
