import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../assets/css/home.module.css";
import ScanImage from "../assets/Scan.jpg";
import { QRCodeCanvas } from "qrcode.react";

export default function Home() {
  const [showQrModal, setShowQrModal] = useState(false);
  const [qrValue, setQrValue] = useState("");
  const navigate = useNavigate();

  const handleScanShipments = () => {
    navigate("/scan-shipments");
  };

  const handleScanPickups = () => {
    navigate("/scan-pickups");
  };

  const handleGenerateQr = () => {
    const storedData = localStorage.getItem("runnerData"); // Replace "sessionData" with the correct key
    if (storedData) {
      const runnerData = JSON.parse(storedData); // Parse the JSON object
      const accessToken = runnerData.accessToken; // Retrieve the access token
      if (accessToken) {
        setQrValue(accessToken); // Set the QR code value to the access token
        setShowQrModal(true);
      } else {
        alert("Access token not found!");
      }
    } else {
      alert("No runner data found in localStorage!");
    }
  };

  const handleCloseQrModal = () => {
    setShowQrModal(false);
  };

  return (
    <div className="login-container">
      <img src={ScanImage} alt="login" className="login-image" />
      <div className={styles.buttonContainer}>
        <button
          style={{
            backgroundColor: "#00347b",
            color: "white",
            height: "50px",
            fontWeight: "bold",
            width: "200px",
            borderRadius: "10px",
            borderColor: "white",
          }}
          onClick={handleScanShipments}
        >
          Scan Shipments
        </button>
        <button
          style={{
            backgroundColor: "#00347b",
            color: "white",
            height: "50px",
            fontWeight: "bold",
            width: "200px",
            borderRadius: "10px",
            borderColor: "white",
          }}
          onClick={handleScanPickups}
        >
          Scan Pickups
        </button>
        <button
          style={{
            backgroundColor: "#00347b",
            color: "white",
            height: "50px",
            fontWeight: "bold",
            width: "200px",
            borderRadius: "10px",
            borderColor: "white",
          }}
          onClick={handleGenerateQr}
        >
          QR Token
        </button>
      </div>

      {showQrModal && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            backgroundColor: "white",
            border: "1px solid #ccc",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
          }}
        >
          <QRCodeCanvas value={qrValue} />
          <button
            onClick={handleCloseQrModal}
            style={{
              marginTop: "10px",
              backgroundColor: "#00347b",
              color: "white",
              height: "50px",
              width: "130px",
            }}
          >
            Close
          </button>
        </div>
      )}
      {showQrModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999,
          }}
          onClick={handleCloseQrModal}
        ></div>
      )}
    </div>
  );
}
