import React, { useState, useEffect, createContext } from "react";
import CameraImg from "../assets/camera.png";
import axios from "axios";
import Logout from "../components/Logout";
import styles from "../assets/css/shipmentOfPickups.module.css";
import { useParams } from "react-router-dom";
import CheckIcon from "../assets/icons/CheckIcon";
import MobileViewPickUp from "../components/MobileViewPickUp";
import ConfirmationPickupDialog from "../components/ConfirmationPickupDialog";
export const CameraPickUpContext = createContext(null);

export default function ShipmentsOfPickups() {
  const runnerData = JSON.parse(localStorage.getItem("runnerData")) || "";
  let { runnerID, accessToken, companyID } = runnerData;
  const { pickupID } = useParams();
  const [query, setQuery] = useState([]);
  const [res, setRes] = useState([]);
  const [isCameraOpen, setCameraOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [shipmentsData, setShipmentsData] = useState();
  const [originalShipments, setOriginalShipments] = useState("");
  const [newShipments, setNewShipments] = useState(0);
  const [filteredShipments, setFilteredShipments] = useState(new Set()); // Use Set to store unique values

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://vsoftrunners.com-eg.net/api/RunnerUsers/V7/GetShipmentsByPickupID/${runnerID}/${pickupID}`,
          {
            headers: {
              companyID: companyID,
              accessToken: accessToken,
            },
          }
        );
        const data = response.data;
        setShipmentsData(data);
        setOriginalShipments(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [accessToken, companyID, runnerID, pickupID]);
  //   =======================================================

  const handleQueryChange = (e) => {
    setQuery(e.target.value.trim().toUpperCase());
  };
  //   =======================================================
  const handleQuerySubmit = (e) => {
    e.preventDefault();
    if (query !== "") {
      processQuery(query.trim());
      setQuery("");
    }
  };

  useEffect(() => {
    if (res.length > 0) {
      res.forEach((qrCode) => {
        processQuery(qrCode.trim());
      });
      setRes([]);
    }
  }, [res]);

  const processQuery = (qrCode) => {
    // Add scanned QR code or query to filteredRes Set
    setFilteredShipments((prevSet) => {
      const newSet = new Set(prevSet); // Copy previous Set
      newSet.add(qrCode); // Add new QR code or query
      return newSet;
    });

    const existingShipment = shipmentsData.find(
      (shipment) => shipment.awb === qrCode
    );

    if (existingShipment) {
      if (existingShipment.Status !== "new") {
        const updatedShipments = shipmentsData.map((shipment) =>
          shipment.awb === qrCode
            ? { ...shipment, Status: "original", Check: <CheckIcon /> }
            : shipment
        );
        setShipmentsData(updatedShipments);
      }
    } else {
      const newShipment = {
        awb: qrCode,
        Status: "new",
        Check: "",
      };
      setShipmentsData((oldData) => [...oldData, newShipment]);
      setNewShipments((count) => count + 1);
    }
  };
  // ==========================================================

  const Shipmentbody = {
    Shipments: shipmentsData?.map((shipment) => ({
      pickupID: pickupID,
      awb: shipment.awb,
      shipmentStatus: shipment.Status === "new" ? 1 : 0,
      isConfirmed: filteredShipments.has(shipment.awb),
    })),
  };
  const filteredArray = Array.from(filteredShipments);
  return (
    <>
      <CameraPickUpContext.Provider value={{ res, setRes }}>
        <div>
          <div className="form-wrapper">
            <form onSubmit={handleQuerySubmit} className="header-form">
              <input
                type="text"
                className="scan-container"
                value={query}
                onChange={handleQueryChange}
              />
            </form>
            <div onClick={() => setCameraOpen((old) => !old)}>
              <img
                src={CameraImg}
                style={{ width: "30px", height: "30px" }}
                alt=""
              />
            </div>
            <Logout />
          </div>
          <div>
            <div className="scanned-status">
              <div className="scanned-title">Original Shipments</div>
              <div className="scanned-number">{originalShipments.length}</div>
            </div>
            <div className="scanned-status">
              <div className="scanned-title">New Shipments</div>
              <div className="scanned-number">{newShipments}</div>
            </div>
            <div className="scanned-status">
              <div className="scanned-title">Total Shipments</div>
              <div className="scanned-number">{shipmentsData?.length}</div>
            </div>
            <div className="scanned-status">
              <div className="scanned-title">Scanned Shipments</div>
              <div className="scanned-number">{filteredArray?.length}</div>
            </div>
            <div className="scanned-container">
              {isCameraOpen ? <MobileViewPickUp /> : null}
            </div>
            {isConfirmOpen && (
              <ConfirmationPickupDialog
                shipments={Shipmentbody}
                shipmentsLength={shipmentsData.length}
                setOpen={setIsConfirmOpen}
              />
            )}
          </div>
          <div>
            <div className={styles.tableContainer}>
              <table className={styles.contentTable}>
                <thead>
                  <tr>
                    <th>Awb</th>
                    <th>Status</th>
                    <th>Check</th>
                  </tr>
                </thead>
                <tbody>
                  {shipmentsData && shipmentsData.length > 0 ? (
                    shipmentsData.map((shipment, index) => (
                      <tr key={index}>
                        <td>{shipment.awb}</td>
                        <td>{shipment.Status}</td>
                        <td>{shipment.Check}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">No shipments available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <button
            className="confirm-btn"
            onClick={() => {
              setIsConfirmOpen(true);
            }}
          >
            Confirm
          </button>
        </div>
      </CameraPickUpContext.Provider>
    </>
  );
}
