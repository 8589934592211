import { useState, useContext } from "react";
import { handleRunnerData } from "../helpers";
import { RunnerContext } from "../Main";
import { Navigate, useNavigate } from "react-router-dom";
import LoginImage from "../assets/login.png";
export default function Login() {
  const navigate = useNavigate();

  const { setRunnerData, runnerData } = useContext(RunnerContext);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    username: "",
    password: "",
    companyID: "",
  });
  const handleFormDataChange = (e) => {
    setFormState((oldState) => ({
      ...oldState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await handleRunnerData().login(formState, setRunnerData);
    setLoading(false);
  };
  if (runnerData) {
    return <Navigate to={"/"} />;
  }
  if (loading) return <>loading</>;

  return (
    <div className="login-container">
      <img src={LoginImage} alt="login" className="login-image" />
      <form onSubmit={handleSubmit} className="login-form">
        <div className="form-control">
          <label htmlFor="username" style={{ fontWeight: "bold" }}>
            {" "}
            username / إسم المستخدم
          </label>
          <input
            required
            className="scan-container"
            name="username"
            value={formState.username}
            onChange={handleFormDataChange}
          />
        </div>
        <div className="form-control">
          <label htmlFor="password" style={{ fontWeight: "bold" }}>
            {" "}
            password / كلمة المرور
          </label>
          <input
            required
            name="password"
            className="scan-container"
            type="password"
            value={formState.password}
            onChange={handleFormDataChange}
          />
        </div>
        <div className="form-control">
          {" "}
          <label htmlFor="companyID" style={{ fontWeight: "bold" }}>
            {" "}
            companyID / كود الشركة
          </label>
          <input
            required
            name="companyID"
            className="scan-container"
            value={formState.companyID}
            onChange={handleFormDataChange}
          />
        </div>
        <button
          type="submit"
          style={{
            backgroundColor: "#00347b",
            color: "white",
            height: "50px",
            fontWeight: "bold",
          }}
        >
          login / تسجيل الدخول
        </button>
      </form>
    </div>
  );
}
