import { useContext, useState } from "react";
import { handleLogout } from "../helpers";
import { RunnerContext } from "../Main";
import LogoutImg from "../assets/logout.png";
export default function Logout() {
  const { runnerData, setRunnerData } = useContext(RunnerContext);

  const [error, setError] = useState();
  const onLogout = async () => {
    try {
      const { baseURL, runnerID, accessToken, companyID } = runnerData;

      await handleLogout(
        baseURL,
        setRunnerData,
        accessToken,
        runnerID,
        companyID
      );
    } catch (err) {
      setRunnerData(null);
      localStorage.removeItem("runnerData");
      console.log("error handle", err.message);
    }
  };
  return (
    <div onClick={onLogout}>
      <img
        src={LogoutImg}
        style={{ width: "30px", height: "30px" }}
        alt="alternative"
      />
    </div>
  );
}
