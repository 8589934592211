import React from "react";

export default function CheckIcon() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="14"
        height="14"
        x="0"
        y="0"
        viewBox="0 0 60 60"
      >
        <g>
          <path
            fill="#6bb577"
            d="m1.541 29.304 20.846 28.205c1.574 2.13 4.777 1.945 6.104-.351L58.614 5.014c1.569-2.716-2.011-5.445-4.144-3.159L23.086 35.472 4.877 25.392c-2.461-1.362-5.018 1.636-3.336 3.912z"
            opacity="1"
            data-original="#6bb577"
          ></path>
          <g fill="#a3e5b0">
            <path
              d="M41.407 20.29a1 1 0 0 1-.737-1.677l8.877-9.667a1 1 0 0 1 1.473 1.353l-8.877 9.667a.997.997 0 0 1-.736.324zM53.293 7.348a1.001 1.001 0 0 1-.737-1.676l1.237-1.348a1 1 0 0 1 1.473 1.352l-1.237 1.348a.997.997 0 0 1-.736.324z"
              fill="#a3e5b0"
              opacity="1"
              data-original="#a3e5b0"
            ></path>
          </g>
        </g>
      </svg>
    </div>
  );
}
